import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import AccountMyCouponsScreen from '../../screens/account/my-coupons'

const AccountMyCouponsPage = (props) => (
  <Router>
    <AccountMyCouponsScreen
      {...props}
      path={`${_.get(props, 'pageContext.locale')}/account/my-coupons`}
    />
  </Router>
)

export default AccountMyCouponsPage
